.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
  min-width: 0;
}
.freeBox__mHmgw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--token-wrFJtVAO5);
  width: 100%;
  min-width: 0;
}
.headerContentContainer___7Jtgn:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.text__a9S53 {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-XMoXRN_s63-);
  line-height: var(--token-MHxx4_Rh4);
}
.img__rdg5S {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  object-position: left bottom;
  border-radius: 15px;
}
.img__rdg5S > picture > img {
  object-fit: cover;
  object-position: left bottom;
}
.button__cxJrg:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  margin-top: 0;
  width: auto;
}
.svg__o5IhK {
  width: 110px;
  height: 26px;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-8j5e4W9rawI);
}
.svg__vpVi6 {
  width: 216px;
  height: 44px;
  align-self: auto;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-TDUv9IlsPLe);
}
