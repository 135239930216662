.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.pageLayoutComponent:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.freeBox__t3FN0 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  min-width: 0;
  min-height: 0;
}
@media (min-width: 600px) {
  .freeBox__t3FN0 {
    padding: 0px;
  }
}
._002Header:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--token-D1V5iINMo);
  width: 100%;
  min-width: 0;
}
@media (min-width: 900px) {
  .background {
    background: url("/plasmic/placeit/images/backgroundPng2.png") top 0% right 0% / 50% auto no-repeat,
      var(--token-D1V5iINMo);
  }
}
@media (min-width: 1024px) {
  .background {
    background: url("/plasmic/placeit/images/backgroundPng2.png") top 0% right 0% / auto 70% no-repeat,
      var(--token-D1V5iINMo);
  }
}
.headerContentContainer:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.text__yBqgn {
  font-size: var(--token-WkKdYF02Z);
  text-align: left;
  width: 100%;
  min-width: 0;
}
.freeBox__gNUg6 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: auto;
  height: auto;
  max-width: 100%;
  padding-top: var(--token-JIfrimaWtNy);
  padding-bottom: var(--token-JIfrimaWtNy);
}
.freeBox__gNUg6 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox__gNUg6 > :global(.__wab_flex-container) > *,
.freeBox__gNUg6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gNUg6 > :global(.__wab_flex-container) > picture > img,
.freeBox__gNUg6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 600px) {
  .freeBox__gNUg6 {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .freeBox__gNUg6 > :global(.__wab_flex-container) {
    align-items: stretch;
    min-width: 0;
  }
}
.freeBox__gYsU2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.freeBox__gYsU2 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-u8uQ-APga));
  height: calc(100% + var(--token-u8uQ-APga));
}
.freeBox__gYsU2 > :global(.__wab_flex-container) > *,
.freeBox__gYsU2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__gYsU2 > :global(.__wab_flex-container) > picture > img,
.freeBox__gYsU2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-u8uQ-APga);
}
@media (min-width: 600px) {
  .freeBox__gYsU2 {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .freeBox__gYsU2 > :global(.__wab_flex-container) {
    flex-direction: row;
    margin-left: calc(0px - var(--token-u8uQ-APga));
    width: calc(100% + var(--token-u8uQ-APga));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .freeBox__gYsU2 > :global(.__wab_flex-container) > *,
  .freeBox__gYsU2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__gYsU2 > :global(.__wab_flex-container) > picture > img,
  .freeBox__gYsU2
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-u8uQ-APga);
    margin-top: 0px;
  }
}
.link__kxDo5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  color: var(--token-TDUv9IlsPLe);
  max-width: 240px;
  max-height: 70px;
}
@media (min-width: 600px) {
  .link__kxDo5 {
    width: auto;
  }
}
.img__txH15 {
  object-fit: contain;
  height: 100%;
  max-height: 70px;
  min-height: 0;
}
.img__txH15 > picture > img {
  object-fit: contain;
}
.link__lhSBf {
  display: flex;
  color: var(--token-TDUv9IlsPLe);
  max-width: 240px;
  flex-direction: column;
  max-height: 70px;
  align-items: flex-start;
  justify-content: flex-start;
}
.img__syH5M {
  object-fit: contain;
  align-self: center;
  object-position: left 50%;
  height: 100%;
  max-height: 70px;
  min-height: 0;
}
.img__syH5M > picture > img {
  object-fit: contain;
  object-position: left 50%;
}
.img__gAcP {
  object-fit: contain;
  max-width: 100%;
  max-height: 104px;
  object-position: left 50%;
}
.img__gAcP > picture > img {
  object-fit: contain;
  object-position: left 50%;
}
@media (min-width: 600px) {
  .img__gAcP {
    height: 144px;
    flex-shrink: 0;
  }
}
.img__sUzju {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  object-position: left bottom;
  border-radius: 15px;
}
.img__sUzju > picture > img {
  object-fit: cover;
  object-position: left bottom;
}
@media (min-width: 1536px) {
  .img__sUzju {
    max-height: 650px;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  margin-top: 0;
  display: none;
}
@media (min-width: 600px) {
  .button:global(.__wab_instance) {
    display: none;
  }
}
.text__rCuQl {
  color: var(--token-TDUv9IlsPLe);
  font-size: var(--token-OVQ_pKinX);
  text-align: left;
}
@media (min-width: 1280px) {
  .text__rCuQl {
    font-size: var(--token-7vjXi0uPm);
  }
}
.text__gyR2K {
  color: var(--token-aLKgcASGV);
  font-size: var(--token-wSGdpGsDD);
  text-align: left;
}
@media (min-width: 1280px) {
  .text__gyR2K {
    font-size: var(--token-hOqCuytDszPG);
  }
}
.svg__n6UNk {
  width: 110px;
  height: 26px;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-8j5e4W9rawI);
}
.svg__y6F1G {
  width: 216px;
  height: 44px;
  align-self: auto;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-TDUv9IlsPLe);
}
.content {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
.content > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-eX3zVaWtY));
  height: calc(100% + var(--token-eX3zVaWtY));
}
.content > :global(.__wab_flex-container) > *,
.content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.content > :global(.__wab_flex-container) > picture > img,
.content
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-eX3zVaWtY);
}
@media (min-width: 900px) {
  .content > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-IXhP74seA));
    height: calc(100% + var(--token-IXhP74seA));
  }
}
@media (min-width: 900px) {
  .content > :global(.__wab_flex-container) > *,
  .content > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .content > :global(.__wab_flex-container) > picture > img,
  .content
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-IXhP74seA);
  }
}
@media (min-width: 1024px) {
  .content {
    padding-left: var(--token-cTiaoKBgu);
    padding-right: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1900px) {
  .content {
    width: 80%;
  }
}
.appOverview {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .appOverview {
    padding-bottom: var(--token-JIfrimaWtNy);
  }
}
@media (min-width: 900px) {
  .appOverview {
    width: 100%;
    min-width: 0;
  }
}
.columns__by1Ts {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__by1Ts > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__by1Ts > :global(.__wab_flex-container) > *,
.columns__by1Ts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__by1Ts > :global(.__wab_flex-container) > picture > img,
.columns__by1Ts
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__by1Ts > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__by1Ts {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__by1Ts > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__by1Ts > :global(.__wab_flex-container) > *,
  .columns__by1Ts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__by1Ts > :global(.__wab_flex-container) > picture > img,
  .columns__by1Ts
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__by1Ts > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__by1Ts > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__by1Ts {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__by1Ts > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__by1Ts > :global(.__wab_flex-container) > *,
  .columns__by1Ts > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__by1Ts > :global(.__wab_flex-container) > picture > img,
  .columns__by1Ts
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__hwa5L {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__hwa5L > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__hwa5L > :global(.__wab_flex-container) > *,
.column__hwa5L > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__hwa5L > :global(.__wab_flex-container) > picture > img,
.column__hwa5L
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .column__hwa5L > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 900px) {
  .column__hwa5L {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .column__hwa5L > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
.heading___78KBi:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  text-align: center;
  color: var(--token-TDUv9IlsPLe);
}
@media (min-width: 600px) {
  .heading___78KBi:global(.__wab_instance) {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .heading___78KBi:global(.__wab_instance) {
    font-size: 40px;
    height: 64px;
    flex-shrink: 0;
  }
}
.heading__jhspM:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  color: #535353;
  text-align: left;
}
@media (min-width: 600px) {
  .heading__jhspM:global(.__wab_instance) {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .heading__jhspM:global(.__wab_instance) {
    font-size: 32px;
  }
}
.text__rr35V {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  padding-top: 0px;
  text-align: left;
  min-width: 0;
}
@media (min-width: 600px) {
  .text__rr35V {
    text-align: left;
  }
}
@media (min-width: 900px) {
  .text__rr35V {
    text-align: left;
  }
}
.userConditionalStack:global(.__wab_instance) {
  width: 100%;
  max-width: 100%;
  object-fit: cover;
  position: relative;
  padding: 0px;
}
.ssoTrigger:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
.text__afCt3 {
  width: 100%;
  height: auto;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  min-width: 0;
}
.column__s3VA {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__s3VA > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.column__s3VA > :global(.__wab_flex-container) > *,
.column__s3VA > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__s3VA > :global(.__wab_flex-container) > picture > img,
.column__s3VA
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 900px) {
  .column__s3VA {
    padding: 0px;
  }
}
.freeBox__w98W0 {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__w98W0 > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox__w98W0 > :global(.__wab_flex-container) > *,
.freeBox__w98W0 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__w98W0 > :global(.__wab_flex-container) > picture > img,
.freeBox__w98W0
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img__yjv1B {
  object-fit: cover;
  max-width: 100%;
}
.img__yjv1B > picture > img {
  object-fit: cover;
}
.text__nPhMs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-left: 0px;
  font-size: 18px;
  min-width: 0;
}
.freeBox___9UmL {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox___9UmL > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox___9UmL > :global(.__wab_flex-container) > *,
.freeBox___9UmL > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___9UmL > :global(.__wab_flex-container) > picture > img,
.freeBox___9UmL
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img__uUdqn {
  object-fit: cover;
  max-width: 100%;
}
.img__uUdqn > picture > img {
  object-fit: cover;
}
.text__haG3E {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-left: 0px;
  font-size: 18px;
  min-width: 0;
}
.freeBox__ozgPa {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__ozgPa > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
}
.freeBox__ozgPa > :global(.__wab_flex-container) > *,
.freeBox__ozgPa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ozgPa > :global(.__wab_flex-container) > picture > img,
.freeBox__ozgPa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
}
.img__rzRrg {
  object-fit: cover;
  max-width: 100%;
}
.img__rzRrg > picture > img {
  object-fit: cover;
}
.text__l65C8 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 18px;
  padding-left: 0px;
  min-width: 0;
}
.mockupsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .mockupsSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns__zeQco {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__zeQco > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__zeQco > :global(.__wab_flex-container) > *,
.columns__zeQco > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__zeQco > :global(.__wab_flex-container) > picture > img,
.columns__zeQco
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__zeQco > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__zeQco {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__zeQco > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__zeQco > :global(.__wab_flex-container) > *,
  .columns__zeQco > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__zeQco > :global(.__wab_flex-container) > picture > img,
  .columns__zeQco
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__zeQco > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__zeQco > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__zeQco > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__zeQco > :global(.__wab_flex-container) > *,
  .columns__zeQco > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__zeQco > :global(.__wab_flex-container) > picture > img,
  .columns__zeQco
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__aOv7U {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__aOv7U > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__aOv7U > :global(.__wab_flex-container) > *,
.column__aOv7U > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__aOv7U > :global(.__wab_flex-container) > picture > img,
.column__aOv7U
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .column__aOv7U > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: center;
  }
}
.img__iqeb6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__iqeb6 > picture > img {
  object-fit: cover;
}
.column___1Eq3E {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column___1Eq3E > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column___1Eq3E > :global(.__wab_flex-container) > *,
.column___1Eq3E > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___1Eq3E > :global(.__wab_flex-container) > picture > img,
.column___1Eq3E
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading___8Ixmj:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  text-align: left;
}
@media (min-width: 900px) {
  .heading___8Ixmj:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__fo4U {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 563px;
  font-size: 18px;
  text-align: left;
  min-width: 0;
  padding: 0px;
}
.text__af4W1 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.designsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .designsSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns__hrSnX {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__hrSnX > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__hrSnX > :global(.__wab_flex-container) > *,
.columns__hrSnX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__hrSnX > :global(.__wab_flex-container) > picture > img,
.columns__hrSnX
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__hrSnX > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__hrSnX {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__hrSnX > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__hrSnX > :global(.__wab_flex-container) > *,
  .columns__hrSnX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__hrSnX > :global(.__wab_flex-container) > picture > img,
  .columns__hrSnX
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__hrSnX > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__hrSnX > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__hrSnX {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__hrSnX > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__hrSnX > :global(.__wab_flex-container) > *,
  .columns__hrSnX > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__hrSnX > :global(.__wab_flex-container) > picture > img,
  .columns__hrSnX
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column__rtfIt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__rtfIt > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__rtfIt > :global(.__wab_flex-container) > *,
.column__rtfIt > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__rtfIt > :global(.__wab_flex-container) > picture > img,
.column__rtfIt
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__e5Txw:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  margin-right: 0px;
  width: 557px;
}
@media (min-width: 900px) {
  .heading__e5Txw:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__gg4Eq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 591px;
  font-size: 18px;
  min-width: 0;
  padding: 0px;
}
.text__sBvdo {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 19px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.column__h7Asc {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column__h7Asc {
    justify-content: center;
    align-items: center;
  }
}
.img__n5MVb {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__n5MVb > picture > img {
  object-fit: cover;
}
.logoSection {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .logoSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
.columns__sTaaj {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__sTaaj > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__sTaaj > :global(.__wab_flex-container) > *,
.columns__sTaaj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__sTaaj > :global(.__wab_flex-container) > picture > img,
.columns__sTaaj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__sTaaj > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__sTaaj {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__sTaaj > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__sTaaj > :global(.__wab_flex-container) > *,
  .columns__sTaaj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__sTaaj > :global(.__wab_flex-container) > picture > img,
  .columns__sTaaj
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__sTaaj > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__sTaaj > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__sTaaj {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__sTaaj > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__sTaaj > :global(.__wab_flex-container) > *,
  .columns__sTaaj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__sTaaj > :global(.__wab_flex-container) > picture > img,
  .columns__sTaaj
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column___0DTl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column___0DTl {
    justify-content: center;
    align-items: center;
  }
}
.img__oQsRc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img__oQsRc > picture > img {
  object-fit: cover;
}
.column__htFh2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column__htFh2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column__htFh2 > :global(.__wab_flex-container) > *,
.column__htFh2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__htFh2 > :global(.__wab_flex-container) > picture > img,
.column__htFh2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__sKjug:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: 32px;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 900px) {
  .heading__sKjug:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__u3Zgh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 626px;
  font-size: 18px;
  padding-top: 0px;
  min-width: 0;
}
.text__tcav2 {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 20px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.videosSection {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 0px 40px;
}
.videosSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-eX3zVaWtY));
  height: calc(100% + var(--token-eX3zVaWtY));
}
.videosSection > :global(.__wab_flex-container) > *,
.videosSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.videosSection > :global(.__wab_flex-container) > picture > img,
.videosSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-eX3zVaWtY);
}
@media (min-width: 900px) {
  .videosSection {
    width: 100%;
    min-width: 0;
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .videosSection > :global(.__wab_flex-container) {
    min-width: 0;
  }
}
.columns__wSeTl {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
  padding: 0px;
}
.columns__wSeTl > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - var(--token-JIfrimaWtNy));
  width: calc(100% + var(--token-JIfrimaWtNy));
  --plsmc-rc-col-gap: var(--token-JIfrimaWtNy);
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.columns__wSeTl > :global(.__wab_flex-container) > *,
.columns__wSeTl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__wSeTl > :global(.__wab_flex-container) > picture > img,
.columns__wSeTl
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-JIfrimaWtNy);
  margin-top: var(--token-JIfrimaWtNy);
}
.columns__wSeTl > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .columns__wSeTl {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 600px) {
  .columns__wSeTl > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    min-width: 0;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .columns__wSeTl > :global(.__wab_flex-container) > *,
  .columns__wSeTl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__wSeTl > :global(.__wab_flex-container) > picture > img,
  .columns__wSeTl
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 600px) {
  .columns__wSeTl > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 600px) {
  .columns__wSeTl > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .columns__wSeTl {
    padding: 0px;
  }
}
@media (min-width: 900px) {
  .columns__wSeTl > :global(.__wab_flex-container) {
    margin-left: calc(0px - var(--token-IXhP74seA));
    width: calc(100% + var(--token-IXhP74seA));
    --plsmc-rc-col-gap: var(--token-IXhP74seA);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .columns__wSeTl > :global(.__wab_flex-container) > *,
  .columns__wSeTl > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__wSeTl > :global(.__wab_flex-container) > picture > img,
  .columns__wSeTl
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-IXhP74seA);
    margin-top: 0px;
  }
}
.column___4WU0N {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.column___4WU0N > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.column___4WU0N > :global(.__wab_flex-container) > *,
.column___4WU0N > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___4WU0N > :global(.__wab_flex-container) > picture > img,
.column___4WU0N
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
.heading__uDeH:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  width: 641px;
}
@media (min-width: 900px) {
  .heading__uDeH:global(.__wab_instance) {
    font-size: 40px;
  }
}
.text__j8Yfn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 582px;
  font-size: 18px;
  padding-top: 0px;
  min-width: 0;
}
.text__giiPs {
  width: 100%;
  height: auto;
  max-width: 800px;
  font-size: 20px;
  color: var(--token-TDUv9IlsPLe);
  padding-left: 0px;
  padding-top: 0px;
  min-width: 0;
}
.column__nyphr {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .column__nyphr {
    justify-content: center;
    align-items: center;
  }
}
.img___9I8Pg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  min-width: 0;
}
.img___9I8Pg > picture > img {
  object-fit: cover;
}
.buttonsSection {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-bottom: 0px;
  min-width: 0;
  padding: var(--token-omGhEM8OI) 0px 0px;
}
.buttonsSection > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-JIfrimaWtNy));
  height: calc(100% + var(--token-JIfrimaWtNy));
}
.buttonsSection > :global(.__wab_flex-container) > *,
.buttonsSection > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.buttonsSection > :global(.__wab_flex-container) > picture > img,
.buttonsSection
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-JIfrimaWtNy);
}
@media (min-width: 600px) {
  .buttonsSection {
    max-width: 1080px;
  }
}
.heading__cCos:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
  font-size: 32px;
  color: #860ee6;
  font-family: "Poppins", sans-serif;
  text-align: center;
}
@media (min-width: 900px) {
  .heading__cCos:global(.__wab_instance) {
    height: 60px;
    flex-shrink: 0;
  }
}
.freeBox__ghRwg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__ghRwg > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.freeBox__ghRwg > :global(.__wab_flex-container) > *,
.freeBox__ghRwg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__ghRwg > :global(.__wab_flex-container) > picture > img,
.freeBox__ghRwg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 600px) {
  .freeBox__ghRwg {
    display: flex;
    flex-direction: row;
  }
}
@media (min-width: 600px) {
  .freeBox__ghRwg > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: calc(0px - var(--token-TCakqYrfy));
    width: calc(100% + var(--token-TCakqYrfy));
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 600px) {
  .freeBox__ghRwg > :global(.__wab_flex-container) > *,
  .freeBox__ghRwg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__ghRwg > :global(.__wab_flex-container) > picture > img,
  .freeBox__ghRwg
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-TCakqYrfy);
    margin-top: 0px;
  }
}
.link___3XLr3 {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  max-width: 237px;
  max-height: 69px;
  min-width: 0;
}
@media (min-width: 600px) {
  .link___3XLr3 {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.img__n2EWg {
  object-fit: contain;
  max-width: 100%;
  max-height: 69px;
}
.img__n2EWg > picture > img {
  object-fit: contain;
}
.link__qmfqA {
  position: relative;
  display: flex;
  width: auto;
  height: auto;
  max-width: 208px;
  max-height: 69px;
}
@media (min-width: 600px) {
  .link__qmfqA {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.img__r0BXk {
  object-fit: contain;
  max-width: 100%;
  max-height: 69px;
}
.img__r0BXk > picture > img {
  object-fit: contain;
}
.freeBox__wuCng {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 104px;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 600px) {
  .freeBox__wuCng {
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 80px;
    padding: 0px;
  }
}
.img__fmBl1 {
  object-fit: contain;
  max-width: 100%;
  max-height: 104px;
}
.img__fmBl1 > picture > img {
  object-fit: contain;
}
.text__pbIMg {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 16px;
  text-align: center;
  min-width: 0;
  padding: 0px 0px 40px;
}
.link__gkWVs {
  color: #0070f3;
  padding-top: 0px;
  padding-bottom: 0px;
}
._017CtaBlock:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.seoTag:global(.__wab_instance) {
  max-width: 100%;
  object-fit: cover;
  position: relative;
}
